.pick-movie-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.pick-movie-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pick-movie-modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.pick-movie-modal select {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.pick-movie-modal button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.pick-movie-modal button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pick-movie-modal button:last-child {
  margin-right: 0;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue top border */
  border-radius: 50%; /* Create a circular shape */
  width: 20px; /* Set the width and height */
  height: 20px;
  animation: spin 1s linear infinite; /* Animation for rotation */
  margin: 20px auto; /* Center the spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); } /* Starting point */
  100% { transform: rotate(360deg); } /* Full rotation */
}
