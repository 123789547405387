.add-movie-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
}

.add-movie-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-movie-modal h2 {
  margin-top: 0;
}

.add-movie-modal form {
  margin-top: 20px;
}

.add-movie-modal form div {
  margin-bottom: 10px;
}

.add-movie-modal form label {
  display: block;
  font-weight: bold;
}

.add-movie-modal form input,
.add-movie-modal form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.add-movie-modal form button[type='submit'] {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-movie-modal form button[type='submit']:hover {
  background-color: #0056b3;
}
.submission-error {
  color: red;
  margin-top: 10px;
}

.submission-status,
.submission-success {
  color: green;
  margin-top: 10px;
}
